html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

h1 {
  color: #183353;
  font-weight: 100;
}

h2 {
  color: #fff;
  text-align: center;
  background-color: #1d3c63;
  margin-top: 0;
  padding: .5rem 1rem;
  font-weight: 100;
}

label {
  color: #5b767b;
}

.main {
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 1rem;
  display: grid;
}

.widget {
  background-color: #f2f2f2;
  border: 5px solid #d97126;
  border-radius: 10px;
  overflow: hidden;
}

.dateCalc {
  min-width: initial;
}

.dateCalc .widget__inputs-container {
  flex-direction: column;
  display: flex;
}

.dateCalc .widget__input-container {
  flex-direction: column;
  margin: .5rem auto;
  display: flex;
}

.dateCalc .widget__input-label {
  text-align: center;
}

.widget__input {
  width: 10rem;
  filter: drop-shadow(0 3px 2px #818181);
  height: 2rem;
  text-align: center;
  border: 1px solid #000;
}

.widget__input:focus {
  outline: none;
}

#days, #months, #years {
  width: 5rem;
}

.widget__output {
  background-color: #f0c6a8;
  border: 1.5px solid #8c8c8c;
}

.dateCalc .widget__output {
  min-height: 41.5px;
  text-align: center;
  border-radius: 25px;
  margin: 1rem 3rem;
  padding: .5rem 0;
}

.dateCalc .widget__output:hover {
  cursor: pointer;
  background-color: #e8aa7d;
}

.dateCalc .widget__output:active {
  background-color: #e08d52;
}

.rm-line-breaks__container {
  flex-direction: column;
  display: flex;
}

.textarea {
  margin: 0 10%;
}

.widget__button {
  width: fit-content;
  margin: 1rem auto;
}

@media only screen and (max-width: 800px) {
  .widget__inputs-container, .widget__outputs-container {
    flex-direction: column;
  }
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*# sourceMappingURL=index.8fe87191.css.map */
