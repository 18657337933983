html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
}

h1 {
	color: hsl(213, 55%, 21%);
	font-weight: 100;
}

h2 {
	font-weight: 100;
	background-color: hsl(213, 55%, 25%);
	color: white;
	margin-top: 0;
	padding: 0.5rem 1rem;
	text-align: center;
}

label {
	color: rgb(91 118 123);
}

.main {
	display: grid;
	grid-template-columns: minmax(0,1fr) auto;
	grid-template-columns: auto minmax(0,1fr);
	column-gap: 1rem;
}

.widget {
	background-color: hsl(0, 0%, 95%);
	border: solid 5px hsl(25, 70%, 50%);
	border-radius: 10px;
	overflow: hidden;
}

.dateCalc {
	min-width: initial;
}

.dateCalc .widget__inputs-container {
	display: flex;
	flex-direction: column;
}

.dateCalc .widget__input-container {
	display: flex;
	flex-direction: column;
	margin: 0.5rem auto;
}

.dateCalc .widget__input-label {
	text-align: center;
}

.widget__input {
	width: 10rem;
	border: solid black 1px;
	filter: drop-shadow(0px 3px 2px rgb(129, 129, 129));
	height: 2rem;
	text-align: center;
}

.widget__input:focus {
	outline: none;
}

#days,
#months,
#years {
	width: 5rem;
}

.widget__output {
	background-color: hsl(25, 70%, 80%);
	border: solid hsl(0, 0%, 55%) 1.5px;
}

.dateCalc .widget__output {
	min-height: 41.5px;
	border-radius: 25px;
	text-align: center;
	padding: 0.5rem 0;
	margin: 1rem 3rem;
}

.dateCalc .widget__output:hover {
	background-color: hsl(25, 70%, 70%);
	cursor: pointer;
}

.dateCalc .widget__output:active {
	background-color: hsl(25, 70%, 60%);
}

.rm-line-breaks__container {
	display: flex;
	flex-direction: column;
}

.textarea {
	margin: 0 10%;
}

.widget__button {
	margin: 1rem auto;
	width: fit-content;

}

@media only screen and (max-width: 800px) {
	.widget__inputs-container {
		flex-direction: column;
	}
	.widget__outputs-container {
		flex-direction: column;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
